import React from 'react';
import { useQuery, gql } from '@apollo/client';
import HandleLoading from '@hoc/handle-loading/handle-loading';

import MainLayout from '@hoc/layout';
import ContactSection from '@components/contact-section/contact-section';
import MapSection from '@components/map-section/map-section';
import BreadCrumbs from '@components/bread-crumbs/bread-crumbs';
import Seo from '@components/seo/seo';

import * as s_wrapper from '@assets/styles/wrapper.module.scss';
import * as s_text from '@assets/styles/text.module.scss';
import './wp-styles.scss';


const PAGE_QUERY = gql`
    query BlogPostsQuery($slug: String) {
        offerBy(slug: $slug) {
            content
        }
    }
`;

const PrivacyPolicy = () => {

  const { loading, error, data } = useQuery(PAGE_QUERY, {
    variables: {slug: 'leasing-bez-bik-big-krd'}
});

  return (
    <MainLayout>

      <BreadCrumbs />

      <Seo
        title='Leasing BEZ BIK, BIG i KRD'
				description='Leasing i wynajem długoterminowy samochodów, maszyn i urządzeń, sprzętów budowlanych. ClickLease to najszybciej rozwijający się broker leasingu na polskim rynku. W swojej ofercie mamy aż 40 firm leasingowych. To szeroki wachlarz możliwości.'
      />

      <section className={`${s_wrapper.apply} ${s_wrapper.apply_height}`}>

        <article className={s_text.text_view}>
        <HandleLoading loading={loading} error={error}>

          <h1>Leasing BEZ BIK, BIG i KRD</h1>

          <div dangerouslySetInnerHTML={{ __html: data?.offerBy.content }}>
            </div>

        </HandleLoading>
          {/* <div className={s_text.double}>
            <div>
              <p>
                Leasing bez BIK, BIG i KRD to doskonałe rozwiązanie dla wszystkich przedsiębiorców
                borykających się z prawidłową płynnością finansową. Mimo negatywnej historii kredytowej
                pomogą oni liczyć na należyte wsparcie i pomoc w dalszym rozwoju prowadzonej przez nich działalności.
              </p>
              <h2>Oferta leasingowa bez BIK, BIG i KRD obejmuje:</h2>
              <ul className={s_text.checkmark_list}>
                <li>Minimalna wpłata wstępna 15%, okres do 60 mcy.</li>
                <li>Uproszczone warunki oraz minimum formalności</li>
                <li>Brak weryfikacji w bazie BIK, KRD, BIG oraz innych,</li>
                <li>Brak konieczności przedstawiania zaświadczeń z ZUS i US</li>
                <li>Decyzję przyznawaną na podstawie indywidualnej oceny każdego Klienta</li>
                <li>Brak ograniczeń wiekowych Leasingobiorcy</li>
                <li><strong>Tylko rejestrowane Przedmioty leasingu</strong>: samochody osobowe, dostawcze, ciągniki oraz inne pojazdy podlegające rejestracji.</li>
              </ul>
            </div>
            <div>
              <p>
                Każdemu Zainteresowanemu zawsze podkreślam, że leasing bez BIK, BIG i KRD jest
                rozwiązaniem, dzięki któremu może on odzyskać stabilizację finansową oraz „stanąć na nogi”,
                jednakże pod pewnymi warunkami. Sukces zależy przede wszystkim od TERMINOWEGO SPŁACANIA RAT LEASINGOWYCH.
                Decydując się na taką formę wsparcia, warto bowiem pamiętać, że właścicielem przedmiotu leasingu jest
                strona finansująca, która w przypadku zaległości finansowych może wypowiedzieć umowę.
              </p>
            </div>
          </div> */}

        </article>

      </section>

      <ContactSection />
      <MapSection />

    </MainLayout>
  );

}

export default PrivacyPolicy;
